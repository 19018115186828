import { ScoreParameter } from '@capturi/api-scoring'
import {
  Box,
  Flex,
  Progress,
  Stack,
  StackProps,
  chakra,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import orderBy from 'lodash/orderBy'
import React from 'react'

import { getFilterInfo } from '../../filter-mapping'

export type ScoreParameterBreakdownProps = {
  parameters: ScoreParameter[]
  hits: {
    parameterUid: string
    conversations: number
    conversationsPercent: number
  }[]
  conversationsTotal: number
  colorScheme?: string
} & StackProps

type ParameterBreakdown = {
  uid: string
  hits: number
  hitRate: number
  filterInfo: ReturnType<typeof getFilterInfo>
  type: string
}

export const ScoreParameterBreakdown: React.FC<
  ScoreParameterBreakdownProps
> = ({ parameters, hits, conversationsTotal, colorScheme, ...restProps }) => {
  const breakdown = React.useMemo(() => {
    const result = hits.reduce<ParameterBreakdown[]>((memo, p) => {
      const scoreParameter = parameters.find((x) => x.uid === p.parameterUid)
      if (!scoreParameter) return memo
      const filterInfo = getFilterInfo(scoreParameter)
      memo.push({
        uid: p.parameterUid,
        hits: p.conversations,
        hitRate:
          p.conversationsPercent !== 0 ? p.conversationsPercent / 100 : 0,
        filterInfo,
        type: scoreParameter.type,
      })
      return memo
    }, [])
    return orderBy(result, [(x) => x.hitRate, (x) => x.type], ['desc', 'asc'])
  }, [parameters, hits])

  return (
    <Stack spacing={4} fontSize="sm" {...restProps}>
      {breakdown.map((x) => (
        <Box key={x.uid}>
          <Flex>
            <chakra.span>{x.filterInfo.label}</chakra.span>
            <Flex ml="auto">
              <chakra.span>
                {i18n.number(x.hitRate, {
                  style: 'percent',
                  maximumFractionDigits: 0,
                })}
              </chakra.span>
              <chakra.span pl={2} color="textMuted">
                {x.hits}/{conversationsTotal}
              </chakra.span>
            </Flex>
          </Flex>
          <Progress
            value={x.hits}
            max={conversationsTotal}
            colorScheme={colorScheme ?? 'segments.primary'}
            h="3px"
          />
        </Box>
      ))}
    </Stack>
  )
}
